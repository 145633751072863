import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  LEADS_FETCH,
  LEADS_FETCH_CALL_PEP,
  LEADS_FETCH_MAIL_INB,
  LEADS_FETCH_NEXT,
  LEADS_FETCH_BY_ID,
  LEADS_FETCH_SOURCE_BY_ID,
  LEADS_SET_FILTERS,
  LEADS_CALL_PEP_SET_FILTERS,
  LEADS_MAIL_INB_SET_FILTERS,
  LEADS_CREATE,
  LEADS_EDIT,
  LEADS_ACTIVE_TOGGLE,
  LEADS_FETCH_AUDITS_BY_ID,
  LEADS_FETCH_NOTES_BY_ID,
  LEADS_ADD_NOTES_BY_ID,
  LEADS_FETCH_EMAIL_BY_ID,
  LEADS_FETCH_EMAIL_THREAD_BY_ID,
  LEADS_ADD_EMAILS_BY_ID,
  LEADS_FETCH_EMAIL_TEMPLATES,
  LEADS_FETCH_EMAIL_TEMPLATE_CONTENT,
  LEADS_EMAIL_ACKNOWLEDGE,
  LEADS_STATS_TODAY,
  LEADS_EMAIL_ACKNOWLEDGE_ALL,
  LEADS_SET_MANUAL_STAGE,
  LEADS_SET_FORN_DATA,
  LEADS_CALL_HISTORY,
  LEADS_CALL_AI_INSIGHT,
  LEADS_CALL_AI_DIS_NEU,
  LEADS_FETCH_MODE,
  LEADS_SET_FILTERS_MODE,
  LEADS_SUBMIT_MANAGER_NOTES,
  LEADS_SUBMIT_STATE,
  PREDICTIVE_PROG_FEED_SUMM_FETCH,
  ORG_FILTERS
} from '../actions';

export function* watchLeadsFetchRequest() {
  const action = LEADS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchModeRequest() {
  const action = LEADS_FETCH_MODE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/${payload.mode||filters.mode}/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchCallPepRequest() {
  const action = LEADS_FETCH_CALL_PEP
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/pep/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchMailInbRequest() {
  const action = LEADS_FETCH_MAIL_INB
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/mail/inbound/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchNextRequest() {
  const action = LEADS_FETCH_NEXT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/next`,
        data: filters
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsFiltersRequest() {
  yield takeEvery(LEADS_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH.requestAction({}))
  })
}

export function* watchLeadsModeFiltersRequest() {
  yield takeEvery(LEADS_SET_FILTERS_MODE.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH_MODE.requestAction({}))
  })
}

export function* watchLeadsCallPepFiltersRequest() {
  yield takeEvery(LEADS_CALL_PEP_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH_CALL_PEP.requestAction({}))
  })
}

export function* watchLeadsMailInbFiltersRequest() {
  yield takeEvery(LEADS_MAIL_INB_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH_MAIL_INB.requestAction({}))
  })
}

export function* watchLeadsFetchIdRequest() {
  const action = LEADS_FETCH_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/fetch/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsFetchAuditsIdRequest() {
  const action = LEADS_FETCH_AUDITS_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/public/audit/${payload.id}`
      }
    },
    dataPath: 'results',
    dataDefault: null,
  }));
}

export function* watchLeadsFetchSourceIdRequest() {
  const action = LEADS_FETCH_SOURCE_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/fetch/${payload.source}/${payload.id}`
      }
    },
    dataPath: 'org',
    dataDefault: null,
  }));
}

export function* watchLeadsCreateRequest() {
  const action = LEADS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'orgs',
    dataDefault: [],
  }));
}

export function* watchLeadsEditRequest() {
  const action = LEADS_EDIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${API_BASE}/v1/leads/edit`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'orgs',
    dataDefault: [],
  }));
}

export function* watchLeadsActiveToggleRequest() {
  const action = LEADS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/leads/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsNotesRequest() {
  const action = LEADS_FETCH_NOTES_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/notes/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsAddNotesRequest() {
  const action = LEADS_ADD_NOTES_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/notes/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailsRequest() {
  const action = LEADS_FETCH_EMAIL_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/fetch/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailThreadRequest() {
  const action = LEADS_FETCH_EMAIL_THREAD_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/fetch/thread/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsAddEmailsRequest() {
  const action = LEADS_ADD_EMAILS_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/comms/mail/send`,
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailTemplatesRequest() {
  const action = LEADS_FETCH_EMAIL_TEMPLATES
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/templates`,
      }
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailTemplateContentRequest() {
  const action = LEADS_FETCH_EMAIL_TEMPLATE_CONTENT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/template/content/${payload.template_id}/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsEmailAcknowledgeRequest() {
  const action = LEADS_EMAIL_ACKNOWLEDGE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/comms/mail/acknowledge/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailAcknowledgeAllRequest() {
  const action = LEADS_EMAIL_ACKNOWLEDGE_ALL
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/comms/mail/acknowledge/all/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsStatsTodayRequest() {
  const action = LEADS_STATS_TODAY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/leads/stats/today${payload.is_pep ? '/pep' : ''}`,
        data: {
          ...payload,
          is_pep: undefined
        }
      }
    },
    dataPath: 'stats',
    dataDefault: {},
  }));
}

export function* watchLeadsSetManualStage() {
  const action = LEADS_SET_MANUAL_STAGE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/manual/stage`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchLeadsSetFormData() {
  const action = LEADS_SET_FORN_DATA
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/form/data`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchLeadsCallHistory() {
  const action = LEADS_CALL_HISTORY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
    },
    requestOptionsFromPayload: (payload) => {
      const { id } = payload
      return {
        url: `${API_BASE}/v1/comms/call/history/${id}`
      }
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsAiInsight() {
  const action = LEADS_CALL_AI_INSIGHT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    requestOptions: {
      method: 'get',
    },
    requestOptionsFromPayload: (payload) => {
      const { psource, source, id } = payload
      return {
        url: `https://m3-dev.53dnorth.com/storm/external/transcription/${psource}/${source}/${id}`,
        headers: {
          Authorization: `Bearer ef0d592a-3a2a-4650-a160-dfd2823d8a30`
        }
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsAiDisNeu() {
  const action = LEADS_CALL_AI_DIS_NEU
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    requestOptions: {
      method: 'get',
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `https://m3-dev.53dnorth.com/storm/external/transcription/marshall/dis-neu`,
        headers: {
          Authorization: `Bearer ef0d592a-3a2a-4650-a160-dfd2823d8a30`
        }
      }
    },
    dataPath: 'results',
    dataDefault: null,
  }));
}

export function* watchLeadsManagerNotesSubmit() {
  const action = LEADS_SUBMIT_MANAGER_NOTES
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/manager/notes`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchLeadsStateSubmit() {
  const action = LEADS_SUBMIT_STATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/state/processed-reason`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchPredicitveProgFeedSumm() {
  const action = PREDICTIVE_PROG_FEED_SUMM_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/predictive/prog-feed-summ`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'results',
    dataDefault: {},
  }));
}

export function* watchOrgFilterRequest() {
  const action = ORG_FILTERS
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${API_BASE}/v1/leads/org/filters`,
    },
    dataPath: 'results',
    dataDefault: {},
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchLeadsFetchRequest),
    fork(watchLeadsFetchModeRequest),
    fork(watchLeadsFetchCallPepRequest),
    fork(watchLeadsFetchMailInbRequest),
    fork(watchLeadsFetchNextRequest),
    fork(watchLeadsFetchIdRequest),
    fork(watchLeadsFetchAuditsIdRequest),
    fork(watchLeadsFetchSourceIdRequest),
    fork(watchLeadsCreateRequest),
    fork(watchLeadsEditRequest),
    fork(watchLeadsActiveToggleRequest),
    fork(watchLeadsFiltersRequest),
    fork(watchLeadsModeFiltersRequest),
    fork(watchLeadsCallPepFiltersRequest),
    fork(watchLeadsMailInbFiltersRequest),
    fork(watchLeadsNotesRequest),
    fork(watchLeadsAddNotesRequest),
    fork(watchLeadsEmailsRequest),
    fork(watchLeadsAddEmailsRequest),
    fork(watchLeadsEmailThreadRequest),
    fork(watchLeadsEmailTemplatesRequest),
    fork(watchLeadsEmailTemplateContentRequest),
    fork(watchLeadsEmailAcknowledgeRequest),
    fork(watchLeadsEmailAcknowledgeAllRequest),
    fork(watchLeadsStatsTodayRequest),
    fork(watchLeadsSetManualStage),
    fork(watchLeadsSetFormData),
    fork(watchLeadsCallHistory),
    fork(watchLeadsAiInsight),
    fork(watchLeadsAiDisNeu),
    fork(watchLeadsManagerNotesSubmit),
    fork(watchLeadsStateSubmit),
    fork(watchPredicitveProgFeedSumm),
    fork(watchOrgFilterRequest),
  ]);
}