import { REHYDRATE } from 'redux-persist';
import {
  LEADS_FETCH,
  LEADS_FETCH_CALL_PEP,
  LEADS_FETCH_MAIL_INB,
  LEADS_FETCH_BY_ID,
  LEADS_FETCH_AUDITS_BY_ID,
  LEADS_FETCH_SOURCE_BY_ID,
  LEADS_FETCH_CLEAR,
  LEADS_SET_SEARCH,
  LEADS_SET_SORT,
  LEADS_SET_VIEW,
  LEADS_SET_FILTERS,
  LEADS_CALL_PEP_SET_FILTERS,
  LEADS_MAIL_INB_SET_FILTERS,
  LEADS_CRUD_MODE,
  LEADS_ACTION_CURRENT_STEP,
  LEADS_FETCH_NOTES_BY_ID,
  LEADS_FETCH_EMAIL_BY_ID,
  LEADS_FETCH_EMAIL_THREAD_BY_ID,
  LEADS_FETCH_EMAIL_THREAD_CLEAR,
  LEADS_LOCKED,
  LEADS_FETCH_EMAIL_TEMPLATES,
  LEADS_STATS_TODAY,
  LEADS_CALL_HISTORY,
  LEADS_FETCH_MODE,
  LEADS_SET_FILTERS_MODE,
  LEADS_CALL_AI_INSIGHT,
  PREDICTIVE_PROG_FEED_SUMM_FETCH,
  ORG_FILTERS,
} from '../actions';
import _ from 'lodash'

const INIT_STATE = {
  list: [],
  list_paging: { total_rows: 0, page_size: 25, page_indx: 0 },
  select_options: { parents: [], programmes: [], parsers: [] },
  lead: null,
  locked_listings: {},
  audits: [],
  notes: [],
  mail: [],
  mail_thread: [],
  mail_templates: [],
  stats: {},
  call_history: [],
  insights: {},
  org_filters: { parents: [], orgs: [] },
  grid_options: {
    leads: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {
        stage: 1
      }
    }
  },
  feedback_insight: [],
  reviews: [],
  review_insight: [],
  actionsFormData: {
    personalInformation: {
      firstName: '',
      lastName: '',
      email: '',
      residentCountry: '',
      nationality: '',
      dialCode: '',
      phoneNumber: '',
      dob: '',
      gender: '',
      maritalStatus: '',
    },
    identification: {
      documentType: 'passport',
      passportCover: '',
      passportDataPage: '',
      nationalIdFront: '',
      nationalIdBack: '',
      driversLicenseFront: '',
      driversLicenseBack: '',
    },
    addressInformation: {
      country: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      sameCorrespondenceAddress: true,
      correspondenceAddress: {
        country: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    financialInformation: {
      taxResident: '',
      tin: '',
      noTin: false,
      noTinReason: '',
      occupation: '',
      annualIncome: '',
      sourceOfWealth: '',
      companyInformation: {
        companyName: '',
        contactNumber: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
  },
  actionsStepStatus: {
    0: { status: 'complete' },
    1: { status: 'complete' },
    2: { status: 'complete' },
    3: { status: 'complete' },
    4: { status: 'complete' },
    5: { status: 'complete' },
    6: { status: 'complete' },
  },
  actionsCurrentStep: 0
};

const getKey = (action) => {
  let key = null
  const allKeys = ['leads']
  if (action.type.indexOf('LEADS_') === 0) {
    key = 'leads'
  }
  return { key, remaining: allKeys.filter(a => a !== key) }
}

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEADS_FETCH.REQUEST:
    case LEADS_FETCH_MODE.REQUEST:
    case LEADS_FETCH_CALL_PEP.REQUEST:
    case LEADS_FETCH_MAIL_INB.REQUEST: {
      const approval_method = _.get(action, 'payload.approval_method')
      const stage = _.get(action, 'payload.stage')
      const outcome = _.get(action, 'payload.outcome')
      const mode = _.get(action, 'payload.mode')
      const page = _.get(action, 'payload.page')
      const page_size = _.get(action, 'payload.page_size')
      const { key } = getKey(action)
      let updates = {}
      if (approval_method !== undefined) {
        updates.approval_method = approval_method
      }
      if (stage !== undefined) {
        updates.stage = stage
      }
      if (page !== undefined) {
        updates.page = page
      }
      if (page_size !== undefined) {
        updates.page_size = page_size
      }
      updates.outcome = outcome ? outcome : undefined

      const filters = {
        ...state.grid_options[key].filters,
        ...updates
        // approval_method: action.payload.approval_method
      }
      if (mode) {
        filters.mode = mode
      }
      // console.log(mode, 'mode')
      // console.log(filters.mode, 'filters.mode')
      // console.log(filters, 'filters')
      if (filters.mode !== 'predictive') {
        filters.survey_nps = undefined
      }

      return { ...state, list: [], grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters
        },
      } }
    }
    case LEADS_FETCH.SUCCESS:
    case LEADS_FETCH_MODE.SUCCESS:
    case LEADS_FETCH_CALL_PEP.SUCCESS:
    case LEADS_FETCH_MAIL_INB.SUCCESS: {
      const { leads, total_rows, page_size, page_indx } = action.payload
      return { ...state, list: leads, list_paging: { total_rows, page_size, page_indx } }
    }
    case LEADS_FETCH_BY_ID.REQUEST:
    case LEADS_FETCH_SOURCE_BY_ID.REQUEST:
    case LEADS_FETCH_CLEAR.REQUEST: {
      return { ...state, lead: null }
    }
    case LEADS_FETCH_BY_ID.SUCCESS:
    case LEADS_FETCH_SOURCE_BY_ID.SUCCESS: {
      return { ...state, lead: action.payload }
    }
    case LEADS_FETCH_AUDITS_BY_ID.REQUEST: {
      return { ...state, audits: [] }
    }
    case LEADS_FETCH_AUDITS_BY_ID.SUCCESS: {
      return { ...state, audits: action.payload }
    }
    case LEADS_FETCH_NOTES_BY_ID.REQUEST: {
      return { ...state, notes: [] }
    }
    case LEADS_FETCH_NOTES_BY_ID.SUCCESS: {
      return { ...state, notes: action.payload }
    }
    case LEADS_FETCH_EMAIL_BY_ID.REQUEST: {
      return { ...state, mail: [] }
    }
    case LEADS_FETCH_EMAIL_BY_ID.SUCCESS: {
      return { ...state, mail: action.payload }
    }
    case LEADS_FETCH_EMAIL_THREAD_BY_ID.REQUEST:
    case LEADS_FETCH_EMAIL_THREAD_CLEAR.REQUEST: {
      return { ...state, mail_thread: [] }
    }
    case LEADS_FETCH_EMAIL_THREAD_BY_ID.SUCCESS: {
      return { ...state, mail_thread: action.payload }
    }
    case LEADS_SET_SEARCH.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          search: action.payload.query
        }
      } }
    }
    case LEADS_SET_SORT.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          sort: action.payload
        }
      } }
    }
    case LEADS_SET_VIEW.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          view: action.payload
        }
      } }
    }
    case LEADS_SET_FILTERS.REQUEST:
    case LEADS_SET_FILTERS_MODE.REQUEST:
    case LEADS_CALL_PEP_SET_FILTERS.REQUEST:
    case LEADS_MAIL_INB_SET_FILTERS.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters: {
            ...state.grid_options[key].filters,
            ...action.payload,
          }
        },
      } }
    }
    case LEADS_CRUD_MODE.REQUEST: {
      const { key } = getKey(action)
      const { mode, data } = action.payload
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          crud_modal: mode,
          crud_data: data||null
        }
      } }
    }
    case LEADS_ACTION_CURRENT_STEP.REQUEST: {
      return { ...state, actionsCurrentStep: typeof action.payload === 'object' ? 0 : action.payload }
    }
    case LEADS_LOCKED.REQUEST: {
      return { ...state, locked_listings: action.payload.listings }
    }
    case LEADS_FETCH_EMAIL_TEMPLATES.REQUEST: {
      return { ...state, mail_templates: [] }
    }
    case LEADS_FETCH_EMAIL_TEMPLATES.SUCCESS: {
      return { ...state, mail_templates: action.payload }
    }
    case LEADS_STATS_TODAY.REQUEST: {
      return { ...state, stats: {} }
    }
    case LEADS_STATS_TODAY.SUCCESS: {
      return { ...state, stats: action.payload }
    }
    case LEADS_CALL_HISTORY.REQUEST: {
      return { ...state, call_history: [] }
    }
    case LEADS_CALL_HISTORY.SUCCESS: {
      return { ...state, call_history: action.payload }
    }
    case LEADS_CALL_AI_INSIGHT.REQUEST: {
      const { psource } = action.payload
      return { ...state, insights: { ...state.insights, [psource]: null } }
    }
    case LEADS_CALL_AI_INSIGHT.SUCCESS: {
      const { source } = action.payload
      return { ...state, insights: { ...state.insights, [source]: action.payload } }
    }
    case PREDICTIVE_PROG_FEED_SUMM_FETCH.SUCCESS: {
      const {
        feedback_insight,
        reviews,
        review_insight
      } = action.payload
      return { ...state, pred_feed_summ: action.payload, feedback_insight, reviews, review_insight }
    }
    case ORG_FILTERS.SUCCESS: {
      return { ...state, org_filters: action.payload }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
