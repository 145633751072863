import { REHYDRATE } from 'redux-persist';
import {
  DNC_FETCH,
} from '../actions';

const INIT_STATE = {
  list: [],
  list_paging: { total_rows: 0, page_size: 25, page_indx: 0 },
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DNC_FETCH.SUCCESS: {
      const { dncs, total_rows, page_size, page_indx } = action.payload
      return { ...state, list: dncs, list_paging: { total_rows, page_size, page_indx } }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
